@font-face {
    font-family: "Quicksand";
    src: url("../../assets/font/Quicksand-Regular.ttf") format("truetype");
    font-weight: 400;
    font-style: normal;
  }

  @font-face {
    font-family: "QuicksandBold";
    src: url("../../assets/font/Quicksand-Bold.ttf") format("truetype");
    font-style: normal;
  }

  @font-face {
    font-family: "Helvetica";
    src: url("../../assets/font/Helvetica.ttf") format("truetype");
    font-style: normal;
  }

  @font-face {
    font-family: "HelveticaBold";
    src: url("../../assets/font/Helvetica-Bold.ttf") format("truetype");
    font-style: normal;
  }