@use "../../styles/partials/colors" as *;
@use "../../styles/partials/mixins" as *;

.main {
  padding-top: 19rem;
  @include tablet {
    padding-top: 29rem;
  }
  @include desktop {
    padding-top: 35rem;
  }

  &__text {
    font-size: 1.8rem;
    text-align: center;
    padding-bottom: 1rem;
    text-transform: uppercase;
    font-size: 1.9rem; /* Kept the original font size */
    color: rgb(249, 234, 255);
    font-weight: bold; /* Added bold font weight */
    text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.2),
      /* Softer dark shadow for depth */ 0 0 8px rgba(156, 88, 215, 0.4),
      /* Subtle purple glow for emphasis */ 0 0 15px rgba(156, 88, 215, 0.4); /* Further glow for effect */
    animation: fadeIn 1s ease-in-out, pulseNeon 2s infinite ease-in-out;

    &--highlight {
      color: #ffffff;
      font-weight: 700;
      text-shadow: 0 0 12px #9b59b6, 0 0 17px #9b59b6, 0 0 22px #9b59b6;
      animation: pulseNeon 2s infinite ease-in-out;
    }
    @include tablet {
      font-size: 3rem;
      padding-bottom: 2rem;
    }
    @include desktop {
      font-size: 3.55rem;
    }
  }

  &__container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  &__wrapper {
    padding: 1.25rem 0 0.5rem 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  &__list {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    &-item {
      font-weight: 700;
      display: flex;
      align-items: center;
      gap: 0.5rem;
      font-family: "HelveticaBold", "sans-serif";
    }

    &-more {
      margin-top: auto;
    }

    &-icon {
      color: purple;
    }

    &-check {
      color: green;
    }
  }

  &__section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 3rem;
    @include desktop {
      gap: 8rem;
    }
  }

  &__icons {
    font-size: 2.5rem;

    &-list {
      display: flex;
      justify-content: center;
      align-items: flex-start;
      gap: 1rem;
    }

    &-facebook {
      color: #0866ff;
      transition: color 0.3s ease;

      &:hover {
        color: #3b5998;
      }
    }

    &-instagram {
      color: #ae40af;
      transition: color 0.3s ease;

      &:hover {
        color: #8b338c;
      }
    }
    animation: fadeIn 1s ease-in-out;
  }

  &__button {
    background-color: #a864c1;
    border-radius: 8px;
    border-style: none;
    box-sizing: border-box;
    color: #ffffff;
    cursor: pointer;
    display: inline-block;
    font-size: 14px;
    font-weight: 500;
    height: 40px;
    line-height: 20px;
    list-style: none;
    margin: 0;
    outline: none;
    padding: 10px 16px;
    position: relative;
    text-align: center;
    text-decoration: none;
    transition: color 100ms;
    vertical-align: baseline;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;

    &:hover,
    .button-1:focus {
      background-color: #86509a;
    }
  }
}
