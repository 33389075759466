@use "../../styles/partials/colors" as *;
@use "../../styles/partials/mixins" as *;

.image-carousel {
  padding-top: 7rem;
  position: fixed;  
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: -2;  

  &__slider {
    height: 100%;

    .slick-prev,
    .slick-next {
      display: none !important;
    }

    .slick-dots {
      bottom: 20px;
    }
  }

  &__slide {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 12rem;

    @include tablet {
      height: 22.5rem;
    }

    @include desktop {
      height: 100vh;
      width: 100vw;
    }
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
 
}
