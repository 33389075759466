@use "../../styles/partials/colors" as *;
@use "../../styles/partials/mixins" as *;

.back-button {
  display: flex;
  align-items: center;
  padding: 0.5rem;
  background-color: #a864c1;
  color: #fff;
  border: none;
  border-radius: 8px;
  font-weight: 700;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: #86509a;
  }

  &:focus {
    outline: none;
  }

  .back-button-text {
    display: none; // Initially hide the text

    @include tablet {
      display: inline; // Show text on tablet
    }

    @include desktop {
      display: inline; // Show text on desktop
    }
  }
}

.back-button-icon {
  font-size: 0.85rem;
  @include tablet {
    margin-right: 0.25rem;
  }
}
