@use "../../styles/partials/mixins" as *;

.dropinplay {
  padding: 0.25rem 2rem 0.5rem;
  margin-top: 7rem;
  background-color: rgba(255, 255, 255, 0.4);
  border-radius: 0.5rem;
  @include tablet {
    padding: 0.5rem 4rem 7rem;
  }
  @include desktop {
    padding: 0.5rem 14rem 6rem;
  }

  &__header {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
    @include tablet {
      margin-bottom: 1.5rem;
    }
    @include desktop {
      margin-bottom: 2rem;
    }
  }

  &__title {
    text-align: center;
    color: #000;
    font-weight: 700;
    margin: 0 auto;
    padding-right: 1.25rem;
    font-size: 1.75rem;
    @include tablet {
      padding-right: 3.5rem;
    }
  }

  &__container {
    @include tablet {
      display: flex;
      justify-content: center;
      align-items: flex-start;
      gap: 2rem;
      margin: 1rem 0;
    }
  }

  &__wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 1rem 0;
    gap: 0.25rem;
    @include tablet {
      margin: 0;
    }
  }

  &__image {
    width: 95%;
    border-radius: 0.5rem;
    @include tablet {
      width: 12.5rem;
    }
    @include desktop {
      width: 16rem;
    }
  }

  &__subtitle-container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  &__subtitle {
    color: #000;
    text-align: left;
    margin: 0.25rem 0;
    line-height: 1.5;
    font-size: 1rem;
    @include tablet {
      font-size: 1.05rem;
    }
    @include desktop {
      font-size: 1.25rem;
    }
  }

  &__pricing {
    margin: 1rem 0;
    padding: 1rem;
    background-color: #f9f9f9ab;
    border-radius: 0.5rem;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    width: 16rem;
    margin: 0 auto;
    @include tablet {
      width: 20rem;
    }

    &-title {
      font-size: 1.5rem;
      font-weight: 700;
      margin-bottom: 1rem;
      color: #333;
      text-align: center;
    }

    &-list {
      list-style: none;
      padding: 0;
      margin: 0;
      color: #555;
      font-size: 1.125rem;
      line-height: 1.6;
      text-align: center;

      li {
        margin-bottom: 0.5rem;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }

  &__hours {
    margin-top: 1rem;

    &-title {
      font-size: 1.5rem;
      font-weight: 700;
      margin-bottom: 1rem;
      color: #333;
      text-align: center;
    }

    p {
      font-size: 1.125rem;
      line-height: 1.6;
      color: #555;
      text-align: center;

      a {
        color: #007bff;
        text-decoration: none;
        font-weight: 600;

        &:hover,
        &:focus {
          color: #0056b3;
          text-decoration: underline;
        }
      }
    }
  }

  &__button {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 1rem;
  }
}
