@use "../../styles/partials/mixins" as *;

.birthday-party {
  padding: 0.25rem 2rem 0.5rem;
  margin-top: 7rem;
  background-color: rgba(255, 255, 255, 0.4);

  @include tablet {
    padding: 0.5rem 4rem 7rem;
  }

  @include desktop {
    padding: 0.5rem 14rem 8rem;
  }

  &__header {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;

    @include tablet {
      margin-bottom: 1.5rem;
    }

    @include desktop {
      margin-bottom: 2rem;
    }
  }

  &__title {
    color: #000;
    font-weight: 700;
    margin: 0 auto;
    text-align: center;
    padding-right: 1.25rem;
    font-size: 1.75rem;

    @include tablet {
      padding-right: 3rem;
    }

    @include desktop {
      padding-right: 5rem;
    }
  }

  &__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
  }

  &__wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;

    @include desktop {
      flex-direction: row;
    }
  }

  &__image {
    width: 100%;
    width: 20rem;
    cursor: pointer;
    transition: transform 0.2s ease-in-out;

    &:hover {
      transform: scale(1.05);
    }

    @include tablet {
      width: 25rem;
    }

    @include desktop {
      width: 30rem;
    }
  }

  &__video {
    width: 100%;
    border-radius: 0.5rem;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);

    @include tablet {
      max-width: 300px;
    }

    @include desktop {
      max-width: 500px;
    }
  }

  &__button {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0;
  }

  &__subtitle {
    margin: 0 auto;
    font-size: 1.25rem;
  }
  &-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

/* Image Modal Styling */
.image-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  animation: fadeIn 0.3s ease-in-out;
}

.image-modal__content {
  max-width: 90%;
  max-height: 90%;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(255, 255, 255, 0.2);
}

.image-modal__close {
  position: absolute;
  top: 20px;
  right: 30px;
  font-size: 2rem;
  color: white;
  cursor: pointer;
  font-weight: bold;
  transition: 0.3s;

  &:hover {
    color: red;
  }
}

/* Fade-in effect */
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
