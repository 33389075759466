@use "./mixins" as *;
@use "./colors" as *;
@use "./fonts" as *;

*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  // font-family: "Quicksand", "sans-serif";
  font-family: "Helvetica", "sans-serif";
  scroll-behavior: smooth;
}

ul {
  list-style-type: none;
}

a {
  text-decoration: none;
  color: unset;
}
