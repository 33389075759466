@use "../../styles/partials/colors" as *;
@use "../../styles/partials/mixins" as *;

.private-training {
  padding: 0.25rem 2rem;
  margin-top: 7rem;
  background-color: rgba(255, 255, 255, 0.4);
  @include tablet {
    padding: 0.5rem 4rem;
  }
  @include desktop {
    padding: 0.5rem 14rem;
  }

  &__header {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
  }

  &__title {
    margin: 0 auto;
    color: #000;
    font-weight: 700;
    text-align: center;
    padding-right: 1rem;
    font-size: 1.75rem;
    @include tablet {
      padding-right: 3rem;
    }
  }

  &__container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    @include tablet {
      flex-direction: row;
      align-items: flex-start;
      gap: 2rem;
      width: 100%;
    }
  }

  &__video {
    width: 100%;
    max-width: 15rem;
    border-radius: 0.5rem;
    margin-bottom: 1rem;
    outline: none;
  }

  &__subtitle {
    color: #000;
    text-align: left;
    margin-bottom: 0.5rem;
    line-height: 1.3;
    @include tablet {
      font-size: 1.05rem;
      line-height: 1.4;
    }
    @include desktop {
      font-size: 1.15rem;
    }
  }

  &__button {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #a864c1;
    border-radius: 8px;
    border: none;
    color: #ffffff;
    cursor: pointer;
    font-size: 14px;
    font-weight: 700;
    height: 40px;
    line-height: 20px;
    padding: 10px 16px;
    text-align: center;
    text-decoration: none;
    transition: background-color 100ms;
    user-select: none;
    touch-action: manipulation;
    margin: 1rem;

    &:hover {
      background-color: #86509a;
    }
  }
}
