@use "../../styles/partials/colors" as *;
@use "../../styles/partials/mixins" as *;

.waiver-form-kids {
  max-width: 100%;
  padding: 1rem;
  margin: 8rem 1rem 1rem;
  display: flex;
  flex-direction: column;
  font-size: 1rem;
  background-color: #fff;
  border-radius: 0.5rem;

  @include tablet {
    max-width: 600px;
    margin: 0 auto;
    margin-top: 9rem;
  }

  @include desktop {
    max-width: 800px;
    margin: 0 auto;
    margin-top: 8.5em;
    padding: 4rem;
  }

  &__button {
    width: 6rem;
    position: relative;
    top: 120px;
    left: 20px;
    @include tablet {
      left: 80px;
    }
    @include desktop {
      left: 230px;
    }
  }

  h2 {
    font-size: 1.85rem;
    margin-bottom: 1rem;
    text-align: center;
    font-weight: 700;

    @include tablet {
      font-size: 2rem;
    }

    @include desktop {
      font-size: 2.5rem;
    }
  }

  h3 {
    margin-top: 1rem;
    margin-bottom: 0.5rem;
    text-align: center;
  }

  label {
    margin-bottom: 1rem;
    font-size: 1rem;
    display: flex;
    flex-direction: column;
    color: #333;

    @include tablet {
      font-size: 1.1rem;
    }

    @include desktop {
      font-size: 1.2rem;
    }

    input {
      padding: 0.75rem;
      margin-top: 0.5rem;
      border: 1px solid #ccc;
      border-radius: 4px;
      font-size: 1rem;

      @include tablet {
        padding: 0.25rem;
      }
    }
  }

  &__content {
    max-height: 400px;
    overflow-y: scroll;
    padding: 0.5rem 1.5rem;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin-bottom: 1rem;
    background-color: #f9f9f9;

    @include tablet {
      max-height: 400px;
    }

    @include desktop {
      max-height: 500px;
    }

    p,
    ol,
    li {
      margin-bottom: 1rem;
    }

    h3 {
      font-size: 1.2rem;
      margin-bottom: 1rem;

      @include tablet {
        font-size: 1.5rem;
      }

      @include desktop {
        font-size: 1.8rem;
      }
    }
  }

  .sigCanvas {
    border: 1px solid #ccc;
    border-radius: 4px;
    margin-bottom: 1rem;
    width: 100%;
    height: 150px;

    @include tablet {
      height: 200px;
    }

    @include desktop {
      height: 250px;
    }
  }

  button {
    padding: 0.75rem;
    margin-top: 1rem;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s ease;

    &:hover {
      background-color: #0056b3;
    }

    &:first-of-type {
      background-color: #007bff;
      margin-bottom: 1rem;

      &:hover {
        background-color: #0056b3;
      }
    }

    @include tablet {
      font-size: 1.1rem;
      padding: 0.75rem;
    }

    @include desktop {
      font-size: 1.2rem;
      padding: 0.75rem;
    }
  }
  &__clear {
    background-color: #ccc !important;
    &:hover {
      background-color: #999 !important;
    }
  }
}
