// @use "../../styles/partials/mixins" as *;

// // Special Events Main Container
// .special-events {
//   padding: 0.25rem 2rem 0.5rem;
//   margin-top: 7rem;
//   background-color: rgba(255, 255, 255, 0.4);

//   @include tablet {
//     padding: 0.5rem 4rem 7rem;
//   }

//   @include desktop {
//     padding: 0.5rem 14rem 8rem;
//   }

//   // Header Section
//   &__header {
//     display: flex;
//     align-items: center;
//     margin-bottom: 1rem;

//     @include tablet {
//       margin-bottom: 1.5rem;
//     }

//     @include desktop {
//       margin-bottom: 2rem;
//     }
//   }

//   &__title {
//     text-align: center;
//     color: #000;
//     font-weight: 700;
//     margin: 0 auto;
//     padding-right: 0.75rem;
//     font-size: 1.75rem;

//     @include tablet {
//       padding-right: 3rem;
//       font-size: 2.5rem;
//     }

//     @include desktop {
//       font-size: 3rem;
//     }
//   }

//   // Intro Section
//   &__intro {
//     text-align: center;
//     margin: 1rem auto 1rem;
//     padding: 0.75rem;
//     background: linear-gradient(
//       135deg,
//       rgba(255, 240, 240, 0.7),
//       rgba(255, 255, 255, 0.5)
//     );
//     border-radius: 12px;
//     box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

//     @include tablet {
//       max-width: 33rem;
//     }

//     @include desktop {
//       max-width: 47rem;
//     }

//     &-text {
//       color: #333;
//       font-weight: 700;
//       line-height: 1.4;

//       @include tablet {
//         font-size: 1.25rem;
//       }

//       @include desktop {
//         font-size: 1.5rem;
//       }

//       &::before,
//       &::after {
//         content: "✨";
//         margin: 0 0.25rem;
//         font-size: 1rem;

//         @include desktop {
//           font-size: 2rem;
//         }
//       }
//     }
//   }

//   // Image Container
//   &__image-container {
//     position: relative;
//     width: 100%;
//     max-width: 600px; // Prevent the container from exceeding screen width
//     margin: 1rem auto;
//     display: flex; // Added to center the button
//     flex-direction: column;
//     justify-content: center; // Horizontally center
//     align-items: center; // Vertically center
//     gap: 1rem;

//     @include tablet {
//       width: 80%;
//     }

//     @include desktop {
//       width: 60%;
//     }
//   }

//   &__image {
//     width: 100%;
//     max-width: 100%; // Prevent image from exceeding container size
//     height: auto; // Maintain aspect ratio
//     border-radius: 8px;
//     margin: 1rem;
//     cursor: pointer;
//     transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;

//     &:hover {
//       transform: scale(1.025);
//       box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
//     }
//   }

//   // Button Styling
//   &__button {
//     display: flex;
//     justify-content: center;
//     align-items: center; // Ensures the text inside the button is centered
//     padding: 0.75rem 1.5rem;
//     font-size: 1rem;
//     font-weight: 700;
//     color: #fff;
//     background: linear-gradient(135deg, #976ed9, #a98fd5); // Original gradient
//     border: none;
//     border-radius: 8px;
//     cursor: pointer;
//     box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
//     transition: background-color 0.3s ease, box-shadow 0.3s ease;

//     &:hover {
//       background: linear-gradient(
//         135deg,
//         #b183e7,
//         #9c74e4
//       ); // Original hover gradient
//       box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
//     }

//     &:focus {
//       outline: none;
//       box-shadow: 0 0 5px rgba(151, 110, 217, 0.8);
//     }
//   }
//   &__subtext {
//     text-align: center;
//     font-weight: 800;
//     font-size: 1.15rem;
//     color: #222;
//     background-color: rgba(255, 255, 255, 0.7);
//     padding: 0.5rem 1.5rem;
//     border-radius: 8px;
//     box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
//     display: inline-block;

//     @include tablet {
//       font-size: 1.5rem;
//       padding: 0.5rem 2rem;
//     }

//     @include desktop {
//       padding: 1rem 2.5rem;
//     }
//   }
// }

// // Image Modal
// .image-modal {
//   position: fixed;
//   top: 0;
//   left: 0;
//   width: 100%;
//   height: 100%;
//   background: rgba(0, 0, 0, 0.8);
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   z-index: 9999;

//   &__content {
//     max-width: 90%;
//     max-height: 90%;
//     border-radius: 8px;
//     box-shadow: 0 4px 10px rgba(255, 255, 255, 0.2);
//   }

//   &__close {
//     position: absolute;
//     top: 20px;
//     right: 30px;
//     font-size: 3rem;
//     color: #fff;
//     cursor: pointer;
//     transition: opacity 0.3s ease;

//     &:hover {
//       opacity: 0.7;
//     }
//   }
// }

@use "../../styles/partials/mixins" as *;

// Special Events Main Container
.special-events {
  padding: 0.25rem 2rem 0.5rem;
  margin-top: 7rem;
  background-color: rgba(255, 255, 255, 0.4);

  @include tablet {
    padding: 0.5rem 4rem 7rem;
  }

  @include desktop {
    padding: 0.5rem 14rem 8rem;
  }

  // Header Section
  &__header {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;

    @include tablet {
      margin-bottom: 1.5rem;
    }

    @include desktop {
      margin-bottom: 2rem;
    }
  }

  &__title {
    text-align: center;
    color: #000;
    font-weight: 700;
    margin: 0 auto;
    padding-right: 0.75rem;
    font-size: 1.75rem;

    @include tablet {
      padding-right: 3rem;
      font-size: 2.5rem;
    }

    @include desktop {
      font-size: 3rem;
    }
  }

  // Intro Section
  &__intro {
    text-align: center;
    margin: 1rem auto 1rem;
    padding: 0.75rem;
    background: linear-gradient(
      135deg,
      rgba(255, 240, 240, 0.7),
      rgba(255, 255, 255, 0.5)
    );
    border-radius: 12px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

    @include tablet {
      max-width: 33rem;
    }

    @include desktop {
      max-width: 47rem;
    }

    &-text {
      color: #333;
      font-weight: 700;
      line-height: 1.4;

      @include tablet {
        font-size: 1.25rem;
      }

      @include desktop {
        font-size: 1.5rem;
      }

      &::before,
      &::after {
        content: "✨";
        margin: 0 0.25rem;
        font-size: 1rem;

        @include desktop {
          font-size: 2rem;
        }
      }
    }
  }

  // Card Wrapper
  &__card-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2rem;
    margin-top: 2rem;

    @include tablet {
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
    }

    @include desktop {
      gap: 3rem;
    }
  }

  // Image Container (Card Style)
  &__image-container {
    background-color: rgba(219, 123, 214, 0.768);
    border-radius: 12px;
    box-shadow: 0 6px 16px rgba(0, 0, 0, 0.1);
    padding: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 360px;
    transition: transform 0.3s ease, box-shadow 0.3s ease;

    &:hover {
      transform: translateY(-4px);
      box-shadow: 0 12px 24px rgba(0, 0, 0, 0.2);
    }

    @include tablet {
      flex: 1 1 45%;
      max-width: 320px;
    }

    @include desktop {
      flex: 1 1 30%;
    }
  }

  // Image Styling
  &__image {
    width: 100%;
    max-width: 100%;
    height: auto;
    border-radius: 8px;
    margin: 1rem;
    cursor: pointer;
    transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;

    &:hover {
      transform: scale(1.025);
      box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    }
  }

  // Button Styling
  &__button {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.75rem 1.5rem;
    font-size: 1rem;
    font-weight: 700;
    color: #fff;
    background: linear-gradient(135deg, #976ed9, #a98fd5);
    border: none;
    border-radius: 8px;
    cursor: pointer;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: background-color 0.3s ease, box-shadow 0.3s ease;

    &:hover {
      background: linear-gradient(135deg, #b183e7, #9c74e4);
      box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
    }

    &:focus {
      outline: none;
      box-shadow: 0 0 5px rgba(151, 110, 217, 0.8);
    }
  }

  // Optional Subtext Styling
  &__subtext {
    text-align: center;
    font-weight: 800;
    font-size: 1.15rem;
    color: #222;
    background-color: rgba(255, 255, 255, 0.7);
    padding: 0.5rem 1.5rem;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    display: inline-block;

    @include tablet {
      font-size: 1.5rem;
      padding: 0.5rem 2rem;
    }

    @include desktop {
      padding: 1rem 2.5rem;
    }
  }
}

// Image Modal
.image-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;

  &__content {
    max-width: 90%;
    max-height: 90%;
    border-radius: 8px;
    box-shadow: 0 4px 10px rgba(255, 255, 255, 0.2);
  }

  &__close {
    position: absolute;
    top: 20px;
    right: 30px;
    font-size: 3rem;
    color: #fff;
    cursor: pointer;
    transition: opacity 0.3s ease;

    &:hover {
      opacity: 0.7;
    }
  }
}
