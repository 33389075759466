@keyframes move {
    100% {
      transform: translate3d(0, 0, 1px) rotate(360deg);
    }
  }
  
  .background {
    position: fixed;
    width: 100%;
    height: 110vh;
    top: 0;
    left: 0;
    background: #d8cbed;
    overflow: hidden;
    z-index: -100;
  }
  
  .background span {
    width: 2vmin;
    height: 2vmin;
    border-radius: 2vmin;
    backface-visibility: hidden;
    position: absolute;
    animation: move;
    animation-duration: 32;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
  }
  
  .background span:nth-child(0) {
    color: #ad4ed0;
    top: 67%;
    left: 55%;
    animation-duration: 164s;
    animation-delay: -153s;
    transform-origin: -14vw -11vh;
    box-shadow: 4vmin 0 0.9416521806063554vmin currentColor;
  }
  .background span:nth-child(1) {
    color: #ad4ed0;
    top: 83%;
    left: 91%;
    animation-duration: 133s;
    animation-delay: -1s;
    transform-origin: 16vw 8vh;
    box-shadow: 4vmin 0 1.2324348451355345vmin currentColor;
  }
  .background span:nth-child(2) {
    color: #a599f5;
    top: 19%;
    left: 74%;
    animation-duration: 150s;
    animation-delay: -22s;
    transform-origin: 23vw -17vh;
    box-shadow: 4vmin 0 0.9332780533099236vmin currentColor;
  }
  .background span:nth-child(3) {
    color: #a599f5;
    top: 89%;
    left: 34%;
    animation-duration: 68s;
    animation-delay: -65s;
    transform-origin: 2vw 18vh;
    box-shadow: 4vmin 0 0.9612782190096862vmin currentColor;
  }
  .background span:nth-child(4) {
    color: #953cfb;
    top: 89%;
    left: 29%;
    animation-duration: 48s;
    animation-delay: -68s;
    transform-origin: -4vw -4vh;
    box-shadow: 4vmin 0 0.9206408728655022vmin currentColor;
  }
  .background span:nth-child(5) {
    color: #ff68e7;
    top: 65%;
    left: 81%;
    animation-duration: 152s;
    animation-delay: -29s;
    transform-origin: 1vw -14vh;
    box-shadow: -4vmin 0 1.0402785811568522vmin currentColor;
  }
  .background span:nth-child(6) {
    color: #a599f5;
    top: 39%;
    left: 75%;
    animation-duration: 62s;
    animation-delay: -153s;
    transform-origin: -7vw 9vh;
    box-shadow: -4vmin 0 0.5095188429438369vmin currentColor;
  }
  .background span:nth-child(7) {
    color: #953cfb;
    top: 76%;
    left: 65%;
    animation-duration: 98s;
    animation-delay: -20s;
    transform-origin: -2vw 3vh;
    box-shadow: -4vmin 0 1.4719055907317502vmin currentColor;
  }
  .background span:nth-child(8) {
    color: #ad4ed0;
    top: 6%;
    left: 46%;
    animation-duration: 23s;
    animation-delay: -28s;
    transform-origin: -1vw 24vh;
    box-shadow: 4vmin 0 1.4594539965105149vmin currentColor;
  }
  .background span:nth-child(9) {
    color: #a599f5;
    top: 43%;
    left: 94%;
    animation-duration: 39s;
    animation-delay: -159s;
    transform-origin: 15vw 3vh;
    box-shadow: -4vmin 0 0.9861587573905677vmin currentColor;
  }
  .background span:nth-child(10) {
    color: #a599f5;
    top: 79%;
    left: 48%;
    animation-duration: 55s;
    animation-delay: -175s;
    transform-origin: 17vw -14vh;
    box-shadow: 4vmin 0 0.7667226371321432vmin currentColor;
  }
  .background span:nth-child(11) {
    color: #953cfb;
    top: 5%;
    left: 45%;
    animation-duration: 177s;
    animation-delay: -101s;
    transform-origin: -6vw 13vh;
    box-shadow: -4vmin 0 1.3397492430339941vmin currentColor;
  }
  .background span:nth-child(12) {
    color: #ff68e7;
    top: 77%;
    left: 67%;
    animation-duration: 72s;
    animation-delay: -142s;
    transform-origin: 14vw 20vh;
    box-shadow: -4vmin 0 1.0181556505109561vmin currentColor;
  }
  .background span:nth-child(13) {
    color: #ad4ed0;
    top: 30%;
    left: 99%;
    animation-duration: 75s;
    animation-delay: -130s;
    transform-origin: -5vw 13vh;
    box-shadow: -4vmin 0 0.8172773775087883vmin currentColor;
  }
  .background span:nth-child(14) {
    color: #953cfb;
    top: 41%;
    left: 76%;
    animation-duration: 94s;
    animation-delay: -172s;
    transform-origin: 22vw 23vh;
    box-shadow: 4vmin 0 0.6574536750998383vmin currentColor;
  }
  .background span:nth-child(15) {
    color: #ff68e7;
    top: 83%;
    left: 10%;
    animation-duration: 90s;
    animation-delay: -61s;
    transform-origin: -11vw -7vh;
    box-shadow: 4vmin 0 1.2153523762021312vmin currentColor;
  }
  .background span:nth-child(16) {
    color: #a599f5;
    top: 13%;
    left: 80%;
    animation-duration: 128s;
    animation-delay: -39s;
    transform-origin: 4vw -6vh;
    box-shadow: 4vmin 0 1.2814792424889045vmin currentColor;
  }
  .background span:nth-child(17) {
    color: #ff68e7;
    top: 44%;
    left: 63%;
    animation-duration: 79s;
    animation-delay: -155s;
    transform-origin: -22vw 17vh;
    box-shadow: 4vmin 0 0.7260209175516661vmin currentColor;
  }
  .background span:nth-child(18) {
    color: #953cfb;
    top: 87%;
    left: 14%;
    animation-duration: 93s;
    animation-delay: -127s;
    transform-origin: 20vw -23vh;
    box-shadow: 4vmin 0 0.8633291415586237vmin currentColor;
  }
  .background span:nth-child(19) {
    color: #ff68e7;
    top: 83%;
    left: 84%;
    animation-duration: 51s;
    animation-delay: -42s;
    transform-origin: 4vw -19vh;
    box-shadow: 4vmin 0 0.6492067202161074vmin currentColor;
  }
  .background span:nth-child(20) {
    color: #953cfb;
    top: 63%;
    left: 47%;
    animation-duration: 58s;
    animation-delay: -151s;
    transform-origin: -15vw 25vh;
    box-shadow: 4vmin 0 0.8872049657405203vmin currentColor;
  }
  .background span:nth-child(21) {
    color: #ad4ed0;
    top: 72%;
    left: 34%;
    animation-duration: 160s;
    animation-delay: -31s;
    transform-origin: 19vw -15vh;
    box-shadow: -4vmin 0 1.0510156740001497vmin currentColor;
  }
  .background span:nth-child(22) {
    color: #953cfb;
    top: 58%;
    left: 13%;
    animation-duration: 40s;
    animation-delay: -87s;
    transform-origin: 8vw 5vh;
    box-shadow: 4vmin 0 0.9233009055313033vmin currentColor;
  }
  .background span:nth-child(23) {
    color: #a599f5;
    top: 98%;
    left: 62%;
    animation-duration: 103s;
    animation-delay: -163s;
    transform-origin: -3vw 12vh;
    box-shadow: -4vmin 0 0.8389453322519342vmin currentColor;
  }
  .background span:nth-child(24) {
    color: #a599f5;
    top: 46%;
    left: 83%;
    animation-duration: 132s;
    animation-delay: -145s;
    transform-origin: 8vw -8vh;
    box-shadow: -4vmin 0 1.4737296171574763vmin currentColor;
  }
  .background span:nth-child(25) {
    color: #a599f5;
    top: 36%;
    left: 53%;
    animation-duration: 58s;
    animation-delay: -5s;
    transform-origin: -21vw -13vh;
    box-shadow: 4vmin 0 1.0346262018890748vmin currentColor;
  }
  .background span:nth-child(26) {
    color: #a599f5;
    top: 91%;
    left: 55%;
    animation-duration: 141s;
    animation-delay: -134s;
    transform-origin: 2vw 9vh;
    box-shadow: -4vmin 0 0.8619886009167657vmin currentColor;
  }
  .background span:nth-child(27) {
    color: #953cfb;
    top: 40%;
    left: 49%;
    animation-duration: 152s;
    animation-delay: -76s;
    transform-origin: -19vw -5vh;
    box-shadow: -4vmin 0 0.8658850378561027vmin currentColor;
  }
  .background span:nth-child(28) {
    color: #ad4ed0;
    top: 40%;
    left: 73%;
    animation-duration: 78s;
    animation-delay: -142s;
    transform-origin: 13vw 20vh;
    box-shadow: -4vmin 0 1.003808601930429vmin currentColor;
  }
  .background span:nth-child(29) {
    color: #953cfb;
    top: 37%;
    left: 14%;
    animation-duration: 120s;
    animation-delay: -144s;
    transform-origin: 14vw 20vh;
    box-shadow: 4vmin 0 0.9395969310835766vmin currentColor;
  }
  .background span:nth-child(30) {
    color: #953cfb;
    top: 12%;
    left: 67%;
    animation-duration: 97s;
    animation-delay: -152s;
    transform-origin: 7vw 0vh;
    box-shadow: -4vmin 0 0.9120739347217783vmin currentColor;
  }
  .background span:nth-child(31) {
    color: #ad4ed0;
    top: 76%;
    left: 65%;
    animation-duration: 91s;
    animation-delay: -58s;
    transform-origin: 0vw -10vh;
    box-shadow: -4vmin 0 1.4399685581769681vmin currentColor;
  }
  .background span:nth-child(32) {
    color: #953cfb;
    top: 67%;
    left: 38%;
    animation-duration: 165s;
    animation-delay: -95s;
    transform-origin: 22vw 12vh;
    box-shadow: 4vmin 0 0.8696905412647722vmin currentColor;
  }
  .background span:nth-child(33) {
    color: #ff68e7;
    top: 12%;
    left: 77%;
    animation-duration: 35s;
    animation-delay: -1s;
    transform-origin: 22vw 15vh;
    box-shadow: -4vmin 0 1.4878580386113927vmin currentColor;
  }
  .background span:nth-child(34) {
    color: #ff68e7;
    top: 94%;
    left: 52%;
    animation-duration: 30s;
    animation-delay: -84s;
    transform-origin: -12vw 22vh;
    box-shadow: 4vmin 0 0.5744417610512464vmin currentColor;
  }
  .background span:nth-child(35) {
    color: #ff68e7;
    top: 73%;
    left: 11%;
    animation-duration: 96s;
    animation-delay: -177s;
    transform-origin: 25vw 15vh;
    box-shadow: -4vmin 0 0.6397004217830244vmin currentColor;
  }
  .background span:nth-child(36) {
    color: #ad4ed0;
    top: 94%;
    left: 61%;
    animation-duration: 56s;
    animation-delay: -6s;
    transform-origin: -4vw -21vh;
    box-shadow: -4vmin 0 0.6323594255028127vmin currentColor;
  }
  .background span:nth-child(37) {
    color: #953cfb;
    top: 5%;
    left: 68%;
    animation-duration: 26s;
    animation-delay: -167s;
    transform-origin: 18vw -2vh;
    box-shadow: 4vmin 0 0.7829271409013883vmin currentColor;
  }
  .background span:nth-child(38) {
    color: #ff68e7;
    top: 95%;
    left: 89%;
    animation-duration: 155s;
    animation-delay: -87s;
    transform-origin: 18vw 21vh;
    box-shadow: 4vmin 0 0.9413873259197258vmin currentColor;
  }
  .background span:nth-child(39) {
    color: #a599f5;
    top: 79%;
    left: 6%;
    animation-duration: 116s;
    animation-delay: -90s;
    transform-origin: -10vw 19vh;
    box-shadow: -4vmin 0 0.8934744540367983vmin currentColor;
  }
  .background span:nth-child(40) {
    color: #a599f5;
    top: 74%;
    left: 77%;
    animation-duration: 95s;
    animation-delay: -157s;
    transform-origin: 17vw 18vh;
    box-shadow: -4vmin 0 0.7366616989071921vmin currentColor;
  }
  .background span:nth-child(41) {
    color: #ff68e7;
    top: 6%;
    left: 23%;
    animation-duration: 147s;
    animation-delay: -178s;
    transform-origin: 1vw 25vh;
    box-shadow: -4vmin 0 0.8015513649838795vmin currentColor;
  }
  .background span:nth-child(42) {
    color: #ad4ed0;
    top: 16%;
    left: 20%;
    animation-duration: 165s;
    animation-delay: -79s;
    transform-origin: 12vw 23vh;
    box-shadow: -4vmin 0 1.3676415192740257vmin currentColor;
  }
  .background span:nth-child(43) {
    color: #953cfb;
    top: 88%;
    left: 71%;
    animation-duration: 23s;
    animation-delay: -12s;
    transform-origin: 13vw 17vh;
    box-shadow: 4vmin 0 1.4178761853082216vmin currentColor;
  }
  .background span:nth-child(44) {
    color: #ff68e7;
    top: 80%;
    left: 28%;
    animation-duration: 142s;
    animation-delay: -91s;
    transform-origin: -5vw -4vh;
    box-shadow: 4vmin 0 0.6727698035031644vmin currentColor;
  }
  .background span:nth-child(45) {
    color: #ff68e7;
    top: 82%;
    left: 38%;
    animation-duration: 95s;
    animation-delay: -109s;
    transform-origin: -17vw 14vh;
    box-shadow: -4vmin 0 0.9907427566270868vmin currentColor;
  }
  .background span:nth-child(46) {
    color: #a599f5;
    top: 52%;
    left: 84%;
    animation-duration: 57s;
    animation-delay: -174s;
    transform-origin: -2vw 1vh;
    box-shadow: -4vmin 0 1.1971499029417203vmin currentColor;
  }
  .background span:nth-child(47) {
    color: #ad4ed0;
    top: 52%;
    left: 98%;
    animation-duration: 185s;
    animation-delay: -21s;
    transform-origin: 6vw -11vh;
    box-shadow: 4vmin 0 0.8696181186652563vmin currentColor;
  }
  .background span:nth-child(48) {
    color: #953cfb;
    top: 9%;
    left: 46%;
    animation-duration: 135s;
    animation-delay: -39s;
    transform-origin: -5vw -6vh;
    box-shadow: 4vmin 0 1.1140202999887725vmin currentColor;
  }
  .background span:nth-child(49) {
    color: #ad4ed0;
    top: 49%;
    left: 24%;
    animation-duration: 71s;
    animation-delay: -125s;
    transform-origin: 25vw -19vh;
    box-shadow: 4vmin 0 0.8577661208292753vmin currentColor;
  }
  