@use "../../styles/partials/colors" as *;
@use "../../styles/partials/mixins" as *;

.waiver__container {
  background-color: #d8f3dc;
  border-radius: 0.5rem;
  padding: 2rem;
  text-align: center;
  @include tablet {
    padding: 2rem 2.5rem;
  }
}

.waiver__content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.waiver__title {
  font-weight: 700;
  font-size: 1.8rem;
  color: #388e3c;
  margin-bottom: 1rem;
}

.waiver__button {
  background-color: #388e3c;
  border: none;
  border-radius: 8px;
  padding: 0.5rem 1.5rem;
  color: white;
  font-size: 1rem;
  font-weight: 700;
  text-transform: uppercase;
  cursor: pointer;
  transition: transform 0.3s ease, box-shadow 0.3s ease;

  &:hover {
    transform: translateY(-2px);
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
  }

  &:active {
    transform: translateY(2px);
  }

  .waiver__link {
    text-decoration: none;
    color: white;
  }
}
