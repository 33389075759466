@use "../../styles/partials/colors" as *;
@use "../../styles/partials/mixins" as *;

.mommyandme {
  margin-top: 7rem;
  padding: 0.25rem 2rem;
  background-color: rgba(255, 255, 255, 0.4);
  @include tablet {
    padding: 0.5rem 4rem;
  }
  @include desktop {
    padding: 0.5rem 14rem 4rem;
  }

  &__header {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
  }

  &__title {
    text-align: center;
    color: #000;
    font-weight: 700;
    margin: 0 auto;
    padding-right: 0.5rem;
    font-size: 1.75rem;
    @include tablet {
      padding-right: 4rem;
    }
  }

  &__slider {
    margin-bottom: 1.5rem;
  }

  &__slide {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__image {
    width: 100%;
    height: 22.5rem;
    object-fit: cover;
    border-radius: 8px;
    @include tablet {
      object-fit: contain;
    }
  }

  &__wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    @include tablet {
      align-items: flex-start;
      width: 36rem;
      margin: 0 auto;
    }
    @include desktop {
      width: 50rem;
    }
  }

  &__subtitle {
    color: #000;
    text-align: left;
    margin: 0.25rem 0;
    @include tablet {
      font-size: 1.05rem;
      line-height: 1.4;
    }
    @include desktop {
      font-size: 1.25rem;
    }
  }

  &__widget {
    margin: 2rem 0;
    text-align: center;
    background-color: rgba(255, 255, 255, 0.8);
    padding: 1rem;
    border-radius: 8px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);

    iframe {
      max-width: 100%;
      border: none;
    }

    @include tablet {
      margin: 2rem auto;
      width: 36rem;
    }

    @include desktop {
      width: 50rem;
    }
  }

  &__button {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    margin-top: 1rem;
    margin-bottom: 1rem;
    background-color: #a864c1;
    border-radius: 8px;
    border: none;
    color: #ffffff;
    cursor: pointer;
    font-size: 14px;
    font-weight: 700;
    height: 40px;
    line-height: 20px;
    padding: 10px 16px;
    text-align: center;
    text-decoration: none;
    transition: background-color 100ms;
    user-select: none;
    touch-action: manipulation;

    &:hover {
      background-color: #86509a;
    }

    &-link {
      color: inherit;
      text-decoration: none;
      display: block;
      width: 100%;
      height: 100%;
      text-align: center;
    }
  }
}
