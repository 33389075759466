@use "../../styles/partials/colors" as *;
@use "../../styles/partials/mixins" as *;

@keyframes pulseNeon {
  0%,
  100% {
    text-shadow: 0 0 12px #9b59b6, 0 0 17px #9b59b6, 0 0 22px #9b59b6;
  }
  50% {
    text-shadow: 0 0 17px #9b59b6, 0 0 22px #9b59b6, 0 0 27px #9b59b6;
  }
}

.services {
  &__title {
    text-transform: uppercase;
    font-size: 1.9rem; /* Kept the original font size */
    color: rgb(249, 234, 255);
    font-weight: bold; /* Added bold font weight */
    text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.2),
      /* Softer dark shadow for depth */ 0 0 8px rgba(156, 88, 215, 0.4),
      /* Subtle purple glow for emphasis */ 0 0 15px rgba(156, 88, 215, 0.4); /* Further glow for effect */
    animation: fadeIn 1s ease-in-out, pulseNeon 2s infinite ease-in-out;

    &--highlight {
      color: #ffffff;
      font-weight: 700;
      text-shadow: 0 0 12px #9b59b6, 0 0 17px #9b59b6, 0 0 22px #9b59b6;
      animation: pulseNeon 2s infinite ease-in-out;
    }
    @include tablet {
      font-size: 2.25rem;
    }
    @include desktop {
      font-size: 3.25rem;
    }
  }

  @keyframes pulseText {
    0%,
    100% {
      text-shadow: 1px 1px 2px rgba(255, 255, 255, 0.3),
        0 0 5px rgba(142, 68, 173, 0.5);
    }
    50% {
      text-shadow: 1px 1px 2px rgba(255, 255, 255, 0.3),
        0 0 10px rgba(142, 68, 173, 0.8);
    }
  }

  &__section-title,
  &__section-title--fitness {
    padding-top: 2.5rem;
    font-size: 1.5rem;
    margin-bottom: 0.75rem;
    color: rgb(255, 255, 255);
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 0.1rem;
    text-shadow: 1px 1px 2px rgba(255, 255, 255, 0.3),
      0 0 3px rgba(142, 68, 173, 0.5), 0 0 5px rgba(142, 68, 173, 0.5);
    font-family: "Helvetica Neue", Arial, sans-serif;
    transition: color 0.3s ease-in-out;
    animation: pulseText 2s infinite ease-in-out;

    @include tablet {
      margin-bottom: 1.5rem;
      padding-top: 3rem;
      font-size: 1.75rem;
    }
  }

  &__section {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    align-items: center;
    @include tablet {
      flex-direction: row;
      justify-content: space-evenly;
      flex-wrap: wrap;
    }
  }

  &__container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  &__waiver {
    width: 15rem;
    padding: 1rem;
    background-color: rgba(255, 192, 203, 0.8);
    border-radius: 15px;
  }

  &__card {
    background-color: #ede7f6; // Light purple background
    box-shadow: 0 6px 10px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    transition: transform 0.3s;
    padding: 1rem;
    width: 300px; // Ensuring consistent width
    margin-bottom: 1.5rem;

    &:hover {
      transform: translateY(-10px);
    }
  }

  &__image-wrapper {
    width: 100%;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__image {
    width: 100%;
    height: 275px;
    object-fit: cover;
    border-radius: 8px;
    transition: transform 0.3s, filter 0.3s;

    &-drop {
      width: 100%;
      height: 275px;
      object-fit: contain;
      border-radius: 8px;
      transition: transform 0.3s, filter 0.3s;
    }

    &:hover {
      transform: scale(1.05);
      filter: brightness(1.1);
    }
  }

  &__card-title {
    font-size: 1.25rem;
    font-weight: 700;
    margin-top: 0.5rem;
    margin-bottom: 1rem;
    font-family: "Helvetica", "sans-serif";
    text-transform: uppercase;
  }

  &__button {
    background-color: #a864c1;
    color: white;
    border: none;
    border-radius: 8px;
    padding: 0.75rem 1.5rem;
    font-size: 1rem;
    font-weight: 700;
    transition: background-color 0.3s;
    cursor: pointer;

    &:hover {
      background-color: #86509a;
    }
  }
}
