.success-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 60vh;
  }
  
  .success-content {
    text-align: center;
    padding: 2rem;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  
    h1 {
      font-size: 2.5rem;
      color: #4caf50; // Green color for success message
      margin-bottom: 1rem;
    }
  
    p {
      font-size: 1.25rem;
      color: #555;
      font-weight: 800;
    }
  }
  