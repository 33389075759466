@use "../../styles/partials/colors" as *;
@use "../../styles/partials/mixins" as *;

.eventplan {
  background-color: #f4d7e0;
  padding: 1.5rem;
  border-radius: 20px;
  text-align: center;
  margin: 1.5rem;
  transition: box-shadow 0.3s, transform 0.3s;

  &:hover {
    transform: scale(1.05);
  }

  @include tablet {
    margin: 2rem 10rem;
  }

  &__title {
    margin-bottom: 1rem;
    color: #d358c1;
    font-size: 1.75rem;
  }
  &__subtitle {
    font-size: 1.15rem;
    color: #000000;
    margin-bottom: 1.5rem;
  }
  &__button {
    background-color: #d358c1;
    color: #ffffff;
    border: none;
    border-radius: 8px;
    padding: 0.75rem 1.5rem;
    font-size: 1.1rem;
    font-weight: 500;
    transition: background-color 0.3s, transform 0.3s, box-shadow 0.3s;
    cursor: pointer;

    &:hover {
      background-color: #a9469a; // Darker purple on hover
      transform: translateY(-3px); // Lift effect
      box-shadow: none; // "Pressed" effect
    }
  }
}
