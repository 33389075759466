@use "../../styles/partials/colors" as *;
@use "../../styles/partials/mixins" as *;

.reviews {
  background-color: rgba(255, 255, 255, 0.95);
  border-radius: 0.5rem;
  padding: 2rem;
  @include tablet {
    padding: 2rem 2.5rem;
  }

  &__title {
    font-weight: 700;
    font-size: 1.8rem;
  }

  &__subtitle {
    font-weight: 700;
  }

  &__socials {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    @include tablet {
      gap: 2rem;
    }
  }

  &__facebook,
  &__google {
    margin: 0.25rem;
    cursor: pointer;
    @include tablet {
      margin: 0.5rem;
    }
  }

  &__facebook {
    color: #0866ff;
    font-size: 5.25rem;
    &:hover {
      color: #3b5998;
    }
  }

  &__google {
    width: 5.5rem;
    height: 5.5rem;
    &:hover {
      opacity: 0.4;
    }
  }

  &__icon {
    color: #ffd700;
  }

  &__text {
    margin: 0.25rem;
    font-weight: 700;
  }

  &__container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  &__wrapper {
    padding: 0.5rem;
    width: 17rem;
    height: 23rem;
    margin: 0 auto;
    border: 1px solid #000;
    border-radius: 8px;
    @include tablet {
      width: 30rem;
      height: 16rem;
    }
  }

  &__area {
    width: 100%;
    background-color: pink;
  }

  &__avatar {
    width: 3.5rem;
    height: 3.5rem;
    border-radius: 50%;
    background-color: #ccc;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-size: 0.85rem;
  }

  &__message {
    padding-bottom: 0.25rem;
  }

  &__testimonial {
    margin-bottom: 0.5rem;
  }

  &__source {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.25rem;
    font-size: 1.5rem;
    color: #333;
    cursor: pointer;

    &-icon {
      font-size: 2rem;
      margin-left: 0.5rem;
      &-google {
        width: 2.55rem;
        height: 2.55rem;
        padding: .25rem;
        &:hover {
          opacity: 0.4;
        }
      }
    }
  }

  &__buttons {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    margin: 0.5rem 0;
    cursor: pointer;
    @include tablet {
      margin-right: 3rem;
      gap: 1.5rem;
    }
    &-birthday {
      margin: 0;
      width: 10.5rem;
      background-image: linear-gradient(135deg, #f56edc, #6454f0);
      border: none;
      border-radius: 8px;
      color: white;
      padding: 0.25rem;
      font-size: 1rem;
      font-weight: 700;
      text-transform: uppercase;
      transition: transform 0.3s ease;
      &:hover {
        transform: translateY(-2px);
      }
      &:active {
        transform: translateY(2px);
      }
    }
    &-fitness {
      margin: 0;
      width: 6rem;
      background-image: linear-gradient(135deg, #f56edc, #6454f0);
      border: none;
      border-radius: 8px;
      color: white;
      padding: 0.25rem;
      font-size: 1rem;
      font-weight: 700;
      text-transform: uppercase;
      transition: transform 0.3s ease;
      &:hover {
        transform: translateY(-2px);
      }
      &:active {
        transform: translateY(2px);
      }
    }
  }
}
