@use "../../styles/partials/colors" as *;
@use "../../styles/partials/mixins" as *;

.events-button {
  background-color: #a864c1;
  border-radius: 8px;
  border: none;
  color: #ffffff;
  cursor: pointer;
  font-size: 14px;
  font-weight: 700;
  height: 40px;
  line-height: 20px;
  padding: 10px 16px;
  text-align: center;
  text-decoration: none;
  transition: background-color 100ms;
  user-select: none;
  touch-action: manipulation;
  margin: 0;
  margin-bottom: .5rem;

  &:hover {
    background-color: #86509a;
  }

  &__button {
    text-align: center;
  }
}
