@use "../../styles/partials/mixins" as *;
@use "../../styles/partials/colors" as *;

.contact {
  margin: 1.5rem 1rem;
  background-color: #ebe2ee;
  border-radius: 1rem;
  text-align: center;
  padding: 1.5rem 2rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

  @include tablet {
    margin: 3rem auto;
    padding: 2rem;
    max-width: 650px;
  }

  @include desktop {
    margin: 4rem auto;
    padding: 2.5rem;
    max-width: 900px;
  }

  &__wrapper {
    display: flex;
    flex-direction: column;
    gap: 2rem;

    @include tablet {
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      gap: 1.5rem;
    }
  }

  &__container {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }

  &__title {
    font-size: 2rem; // Slightly smaller font for mobile
    margin-bottom: 1.5rem;
    font-weight: 700;
    color: #212121;
    position: relative;
    @include tablet {
      font-size: 3rem;
    }

    &::after {
      content: "";
      display: block;
      width: 60%;
      height: 3px;
      background-color: #a864c1;
      margin: 0.5rem auto 0;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      bottom: -3px;
      @include tablet {
        width: 40%;
      }
      @include desktop {
        width: 30%;
      }
    }
  }

  &__form-group {
    margin-bottom: 1rem;
    width: 100%;
    text-align: left;

    @include tablet {
      text-align: center;
    }
  }

  &__form-group--row {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;

    @include tablet {
      flex-wrap: nowrap;
    }
  }

  &__form-group--half {
    flex: 1;
  }

  &__label {
    font-size: 1rem; // Adjust for mobile readability
    margin-bottom: 0.5rem;
    display: flex;
    color: #212121;
  }

  &__input,
  &__textarea-message,
  &__select {
    width: 100%;
    padding: 0.75rem 1rem;
    border-radius: 0.5rem;
    border: 1px solid #ccc;
    font-size: 1rem;
    transition: border-color 0.3s ease, box-shadow 0.3s ease;

    &:focus {
      border-color: #a864c1;
      box-shadow: 0 0 5px rgba(168, 100, 193, 0.5);
      outline: none;
    }
  }

  &__textarea-message {
    height: 8rem;
    resize: none;
  }

  &__button {
    background-color: #a864c1;
    border-radius: 0.5rem;
    border: none;
    color: #ffffff;
    cursor: pointer;
    font-size: 1rem;
    padding: 0.75rem 2rem;
    transition: background-color 0.3s ease, transform 0.3s ease;

    &:hover,
    &:focus {
      background-color: #86509a;
      transform: translateY(-2px);
    }
  }

  &__map {
    flex: 1;
    width: 100%;

    .contact__iframe {
      width: 100%;
      height: 300px; // Slightly reduced height for mobile
      border-radius: 1rem;
      border: none;
      box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
      @include tablet {
        height: 450px;
      }
    }
  }
}
